<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :action.sync="action"
          :disabled.sync="disabled"
          :contentHeight.sync="contentHeight"
          :apprMode="true"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action-detail-appr',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'actionPlan',
      action: {
        saiInternalActionId: '',  // 내부심사 수행계획서 일련번호
        plantCd: '',  // 사업장코드
        actionName: '',  // 실시계획서명
        actionStartDate: '',  // 심사시작일
        actionEndDate: '',  // 심사종료일
        period: [],
        saiInternalActionStepCd: '',  // 내부심사 수행계획서 진행상태
        saiInternalActionTypeCd: '',  // 심사구분
        auditPurposeScope: '',  // 심사 목적 및 범위
        auditPreRequire: '',  // 심사 준비 요청사항
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regDtStr: '', 
        regUserName: '',  // 등록자
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        auditTeams: [], // 심사팀
        deleteAuditTeams: [], // 심사팀 [삭제]
        actionPlans: [], // 실시계획
        deleteActionPlans: [], // 실시계획 [삭제]
        targetDepts: [], // 피심사팀
        deleteTargetDepts: [], // 피심사팀 [삭제]
      },
      editable: true,
      detailUrl: '',
      yearPlanAuditTeamListUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    disabled() {
      return this.action.saiInternalActionStepCd === 'IA00000005'
        || this.action.approvalStatusCd === 'ASC0000001'
    },
    tabItems() {
      let _items = [
        { name: 'actionPlan', icon: 'info', label: '수행계획서', component: () => import(`${'@/pages/sai/internalAudit/internalActionInfo.vue'}`) },
      ]
      if (this.popupParam.saiInternalActionId) {
        _items.push({ name: 'checklist', icon: 'info', label: '체크리스트(사전준비)', component: () => import(`${'@/pages/sai/internalAudit/internalActionChecklist.vue'}`) })
      }
      return _items;
    } 
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.internal.action.get.url;
      this.yearPlanAuditTeamListUrl = selectConfig.sai.internal.yearPlan.auditTeam.list.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiInternalActionId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiInternalActionId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.action, _result.data)
          this.$set(this.action, 'period', [this.action.actionStartDate, this.action.actionEndDate])
          if (this.action.actionPlans && this.action.actionPlans.length > 0) {
            this.$_.forEach(this.action.actionPlans, item => {
              item.dates = [item.startDate, item.endDate]
            })
          }
        },);
      } else {
        // 신규등록인 경우
        this.$set(this.action, 'saiInternalActionStepCd', 'IA00000001'); // 직성중
        this.$set(this.action, 'regDtStr', this.$comm.getToday()); // 작성일
        this.$set(this.action, 'regUserName', this.$store.getters.user.userName); // 작성자
        // 당해년도 연간 내부심사 계획서의 심사팀을 조회 후 표시
        this.$http.url = this.yearPlanAuditTeamListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          planYear: this.$comm.getThisYear(),
        };
        this.$http.request((_result) => {
          if (_result.data && _result.data.length > 0) {
            this.$_.forEach(_result.data, item => {
              item.editFlag = 'C';
              item.regUserId = this.$store.getters.user.userId;
            })
          } else {
            _result.data = [];
          }
          this.$set(this.action, 'auditTeams', _result.data)
        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>